<template>
  <div class="mb-10 flex flex-col justify-center items-center">
    <!-- <HeaderGlobal :step="$t('global_step_01_03')" :title="$t('global_header_registration_method')" /> -->
    <SignupHeader :step="$t('global_step_01_02')" :title="$t('global_header_personal_details')" back="" />
    <div class="w-10/12 mb-10">
      <InputImage
        @blurred="inputImg"
        :title="$t('global_add_profile_picture')"
      />
      <h1 class="text-xl font-proximaMedium">
        {{ $t("after_verification_title") }}
      </h1>
      <CompleteprofileForms
        class="mt-8"
        :checkboxNews="checkboxNews"
        :email_address="email_address"
        :phone_number="phone_number"
        :provider="provider"
        ref="child"
      />
      <div class="mt-2 text-xs font-proximaNormal flex items-start">
        <input class="mt-1" type="checkbox" v-model="checkboxNews" />
        <p class="ml-2 mt-1">
          {{ $t("after_verification_checkbox_news") }}
        </p>
      </div>
      <SubmitButton
        :title="$t('after_verification_continue_button')"
        background="bg-primary"
        @click.native="submitForm"
      />
    </div>
  </div>
</template>

<script>
import SignupHeader from "../../primaryregistration/SignupHeader.vue";
import InputImage from "../../forms/InputImage.vue";
import CompleteprofileForms from "./CompleteprofileForms.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";

export default {
  props: {
    email_address: String,
    phone_number: String,
    provider: String,
  },
  components: {
    SignupHeader,
    InputImage,
    CompleteprofileForms,
    SubmitButton,
  },
  data() {
    return {
      image: null,
      checkboxNews: false,
    };
  },
  methods: {
    inputImg(val) {
      this.image = val;
    },
    submitForm() {
      this.$refs.child.submitForm(this.image);
    },
  },
};
</script>

<style></style>
